$theme-colors: (
  "primary": #26d,
);

@import "../node_modules/bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --primary: #2266dd;
  --secondary: #223448;
  --tertiary: #040c1a;
  --medium-gray: #737373;
  --light-gray: #ddd;
  --dark-gray: #222;
}

html,
body {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#scrollContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden auto;
  height: 100%;
  scroll-behavior: smooth;
}

.snap-y-mandatory {
  scroll-snap-type: y mandatory;
}

.snap-y-proximity {
  scroll-snap-type: y proximity;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "./style/interactiveImage.scss";
@import "./style/socialProofSection.scss";
@import "./style/App.scss";
