@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&display=swap");

Container {
  background-color: black;
}

.font1 {
  font-family: "Abhaya Libre", serif;
}

.fontLarge {
  font-size: 2rem;
}

.fontLargest {
  font-size: 3.5rem;
}

p {
  font-weight: 400;
}

.heroParagraph {
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--dark-gray);
}

.move-left {
  transform: translateX(-50%);
}

h1 {
  transition: 0.2s;
  opacity: 1;

  &.invisible {
    opacity: 0;
  }

  &.heroTitle {
    color: var(--tertiary);
    font-family: monospace;
  }
}

.scrollStart {
  scroll-snap-align: start;
}

.navbar {
  transition: 1s;

  &.hidden {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .navbar-brand {
    position: relative;
    transform: translate(calc(-50%), 0px);
    left: 50%;
  }
}

.navbar-toggler {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;

  .navbar-toggle-icon {
    position: absolute;
    width: 28px;
    height: 2px;
    background-color: var(--medium-gray);
    transition: 0.2s;

    &.middle-bar {
      position: relative;
    }
  }

  &:active .navbar-toggle-icon {
    background-color: var(--dark-gray);
  }

  &.collapsed .navbar-toggle-icon {
    &.top-bar {
      transform: translate(0, -8px);
    }

    &.bottom-bar {
      transform: translate(0, 8px);
    }
  }

  &:not(.collapsed) .navbar-toggle-icon {
    &.top-bar {
      transform: rotate(-45deg);
    }

    &.middle-bar {
      opacity: 0;
    }

    &.bottom-bar {
      transform: rotate(45deg);
    }
  }
}

.color-nav {
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
}

.nav-item .btn {
  font-weight: 600;
}

/*
CALL TO ACTION SECTION
*/

Button.cta-button {
  width: 200px;
  font-weight: 600;
}

.socialLogo {
  transition: 0.2s;
  filter: brightness(100%);

  &.darkTheme:hover {
    filter: brightness(200%);
  }

  &:not(.darkTheme):hover {
    filter: brightness(0%);
  }
}

/*
SKILLS
*/

.notableWork {
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--medium-gray);
}

.list-group-item {
  transition: 0.2s;

  &:hover {
    padding-left: 40px;
  }
}

.showmorelesstext {
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    color: var(--primary);
  }
}

/*
HIGHLIGHTED WORK
*/

h3 {
  font-weight: 700;
  font-size: 1.4rem;
}

.rounded-lg {
  border-radius: 20px;
}

.highlightedWorkImage {
  width: 250px;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.1);
  transition: 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 2px 2px 10px 5px rgba(0, 0, 50, 0.2);
  }
}

.primaryLink {
  color: var(--primary) !important;
  font-weight: 700;
}

/*
FOOTER
*/

.footer {
  background-color: var(--dark-gray);
  font-size: 0.9rem;
  margin-top: 5rem;
  color: white;
}

/*
SPLASH
*/

#splash {
  scroll-snap-align: start;

  width: 100vw;
  height: 100%;
  color: white;

  position: relative;
  overflow: hidden;

  .splashText {
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    object-fit: cover;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .continueChevron {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transition: 0.2s;
    z-index: 1;
    transform: translate(-50%, 0);
    pointer-events: all;

    &:hover {
      transform: translate(-50%, -5px);
      cursor: pointer;
    }
  }
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#mainBody {
  padding: 0 1rem;
}