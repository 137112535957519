.interactiveImage {
  background-color: var(--secondary);
  margin: 1.5rem;
  transform: translate(-0.5rem, 0.5rem);
  transition: 0.2s;
}

.interactiveImage.active {
  transform: translate(-1rem, 1rem);
}

.imageCardImage {
  transition: 0.2s;
  transform: translate(1rem, -1rem);
  width: 100%;
  height: 100%;
}

.interactiveImage.active .imageCardImage {
  position: relative;
  transform: translate(2rem, -2rem);
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.2);
}
